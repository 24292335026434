












import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Exception403'
})
export default class Exception403 extends Vue {
  private toHome() {
    this.$router.push({ path: '/' })
  }
}
